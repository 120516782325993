/* Pagination.css */

/* Estilize o contêiner da paginação */
.pagination {
    max-width: 100%;
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
}

.pagination li a{
    margin: 0 5px;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Estilize a página ativa */
.pagination .active a{
    background-color: #333;
    color: #fff;
}

/* Estilize os botões Anterior e Próxima */
.custom-previous,
.custom-next {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Estilize as quebras entre as páginas */
.custom-break {
    margin: 0 5px;
}