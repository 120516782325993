footer {
    background: #3b0251;
    color: white;
    padding: 20px;
    position: relative;
    padding-bottom: 100px;
}

.container-footer {
    display: flex;
    justify-content: space-between;
}

.container-footer .container-logo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-footer .container-logo .titulo_footer {
    text-align: center;
    color: #fea259;
}

.container-footer .container-logo .descricao_footer {
    text-align: justify;
    font-style: italic;
    color: #fff;
    padding: 0px 20px;
}

.container-map {
    flex: 1;
}

.map {
    width: 100%;
}

.map iframe {
    width: 100%;
}

/* Media query para telas menores */
@media (max-width: 768px) {

    .container-footer {
        flex-direction: column;
    }

    .container-footer .container-logo {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }

    .container-map {
        width: 100%;
    }
}