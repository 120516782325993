.audio-player-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #2c013d;
    padding: 10px 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 0px;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
}

.audio-player-descricao {
    flex: 1;
    display: flex;    
}

.audio-player-image {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border: solid 1px #16011e;
    margin-right: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.audio-player-titulo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    overflow: hidden;
}

.programa-atual {
    font-size: 1.2rem;
    font-weight: bold;
    color: #fea259;
    text-wrap: nowrap;
}

.programa-horario {
    font-size: 1rem;
    font-weight: 100;
    color: #fff;
}

.audio-player-control {
    flex: 1;
    display: flex;
    justify-content: center;
}

.audio-player-control button {
    border: none;
    background: #fea259;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    cursor: pointer;
    box-shadow: none;
    transition: all 0.5s ease-in;
}

.audio-player-control button:hover {
    box-shadow: 4px 4px 10px -5px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in;
}

.icone-control {
    font-size: 1.5rem;
    color: #2c013d;
}

.audio-player-volume {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.icone-volume {
    font-size: 1rem;
    color: #FFF;
    margin-right: 5px;
}

.thin-range {
    accent-color: #fea259;
}

@media (max-width: 768px) {

    .audio-player-descricao {
        flex: none;
        max-width: 205px;
    }

    .audio-player-volume {
        display: none;
    }

    .audio-player-control {
        width: 80px;
        display: flex;
        justify-content: flex-end;
    }
}