.contato-container {}

.contato-container form {}

.contato-container form input,
.contato-container form textarea {
    width: 100%;
    padding: 12px 10px;
    border: solid 1px #d8d8d8;
    color: #555;
    border-radius: 0px;
    box-sizing: border-box;
}

.contato-container form label {
    display: block;
    margin-top: 10px;
    font-weight: 300;
}

.contato-container .form-footer {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 15px;
}

.contato-container .form-footer button {
    padding: 15px 25px;
    border: none;
    border-radius: 0px;
    background: #27ae60;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.contato-container .form-footer button:hover {
    background: #219653;
}