.news-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.news-container a {
    text-decoration: none;
    color: #333;
    position: absolute;
    width: 100%;
    height: 100%;
}

.news-card {
    width: calc(25% - 20px);
    margin: 10px;
    position: relative;
    padding: 7px;
    box-sizing: border-box;
    transition: all 0.5s ease-in;
}

.news-card:hover {
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in;
}

.news-card .card-image {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    /* Evita a repetição da imagem */
    background-position: center;
    /* Centraliza a imagem no contêiner */
    border: solid 1px #e9e9e9;

}

/* Media query para telas menores */
@media (max-width: 768px) {

    /* Exibir apenas 1 card por linha em telas menores */
    .news-card {
        width: 100%;
        /* 100% da largura do contêiner pai (uma por linha) */
        margin: 10px 0;
        /* Espaçamento entre os cards */
        /* Outros estilos para telas menores */
    }
}

/* INTERNA */

.news-container-interna {}

.news-container-interna img {
    max-width: 100%;
}

.news-interna-header {
    margin-bottom: 40px;
}

.news-container-interna h2 {
    font-weight: lighter;
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 1.4rem;
}

.news-container-interna h1 {
    font-size: 2.5rem;
    margin-bottom: 5px;
}

.news-container-interna .data {
    font-size: 0.9rem;
    font-weight: 300;
}